import axios, { AxiosResponse } from 'axios';
import * as Yup from 'yup'
import { FC, useState, useEffect, ChangeEvent } from 'react'
import { DisplayLoader } from '../../utils/loader';
// @ts-ignore
import html2pdf from 'html2pdf.js';
import { Field, Form, Formik, FormikValues } from 'formik';
import { ErrorDisplay } from '../../modules/components/common/ErrorDisplay';
import { Modal } from 'react-bootstrap';
import { jsPDF } from "jspdf";
import { useAuth } from '../../modules/auth';
import { PrintSection } from '../../modules/components/display/PrintSection';
import { challengeTypeToPresentTypeMap } from '../../models/Challenge';
import { generateOMRSheet } from '../../utils/generateOMR';
import { useParams } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { Section } from '../../models/Section';
import PageContent from '../print/PageContent';
import { PrintExplanation } from '../../modules/components/display/PrintExplanation';
import { PrintAnswers } from '../../modules/components/display/PrintAnswer';
type ApiParams = { id: string };
const PrintSections = () => {
  const { currentUser, logout } = useAuth()
  interface LocationState {
    sections: Section[];
    title: string  // sections is an array of Section objects
  }
  const targetTestToDifficultyLevel: any = {
    'cet': 'Easy',
    'mains': 'Medium',
    'neet': 'Medium',
    'advanced': 'Hard',
  }
  const location = useLocation();
  const { sections, title } = location.state as LocationState;
  let [showAnswer, setShowAnswer] = useState<boolean>(false);
  let [showExplanation, setShowExplanation] = useState<boolean>(false);
  let [showTopics, setShowTopics] = useState<boolean>(false);
  let [isOffline, setIsOffline] = useState<boolean>(false);
  let [challenge, setChallenge] = useState<any>({});
  let [institution, setInstitution] = useState<any>({});
  // let [sections, setSections] = useState<any>({});
  useEffect(() => {
    DisplayLoader(true, '');
    axios.get(process.env.REACT_APP_API_URL + '/institution/').then((reply) => {
      if (reply?.data) {
        setInstitution({ name: reply.data.name, logoUrl: reply.data.profileDetails?.logoUrl });
      } else {
        //TODO
      }
      DisplayLoader(false, '')
    }, (error) => {
      DisplayLoader(false, '')
      //reject(error)//TODO
    });
  }, [])
  function addWaterMark(doc: any) {
    var totalPages = doc.internal.getNumberOfPages();

    for (let i = 1; i <= totalPages; i++) {
      doc.setPage(i);
      //doc.addImage(imgData, 'PNG', 40, 40, 75, 75);
      doc.setTextColor(0, 0, 0, 0.3);
      doc.text(doc.internal.pageSize.width / 2 - 20, doc.internal.pageSize.height * 2 / 3, 'Olearno');
      doc.text(doc.internal.pageSize.width / 2 - 20, doc.internal.pageSize.height / 3, 'Olearno');
    }

    return doc;
  }
  const print = () => {
    DisplayLoader(true, 'Downloading questions....')
    const element = document.getElementById('question-print');
    const answerelement = document.getElementById('explain-print');
    html2pdf()
      .set({
        filename: title + ' - Questions.pdf',
        margin: 10,
        jsPDF: {
          unit: 'pt', // Uses points as the unit, which is ideal for print-ready PDFs
          format: 'a4',
          compress: true, // Compress the output PDF, if necessary
        },
        html2canvas: {
          useCORS: true,
          allowTaint: false,
          scale: 3// Higher scale for better text rendering, adjust as needed
        }
      })
      .from(element)
      .save()
      .then(() => { DisplayLoader(true, 'Downloading Answers and Solutions....') })
      .then(() => {
        html2pdf()
          .set({
            filename: title + ' - Answers.pdf',
            margin: 10,
            jsPDF: {
              unit: 'pt', // Uses points as the unit, which is ideal for print-ready PDFs
              format: 'a4',
              compress: true, // Compress the output PDF, if necessary
            },
            html2canvas: {
              useCORS: true,
              allowTaint: false,
              scale: 3// Higher scale for better text rendering, adjust as needed
            }
          })
          .from(answerelement)
          .save()
          .then(() => { DisplayLoader(false, '') });
      });
  }
  function PrintWord() {
    DisplayLoader(true, '')
    let filename = challenge?.heading;
    var preHtml = "<html xmlns:o='urn:schemas-microsoft-com:office:office' xmlns:w='urn:schemas-microsoft-com:office:word' xmlns='http://www.w3.org/TR/REC-html40'><head><meta charset='utf-8'><title>Export HTML To Doc</title></head><body>";
    var postHtml = "</body></html>";
    var html = preHtml + (document.getElementById('print') as HTMLElement).innerHTML + postHtml;
    var blob = new Blob(['\ufeff', html], {
      type: 'application/msword'
    });
    var url = 'data:application/vnd.ms-word;charset=utf-8,' + encodeURIComponent(html);
    filename = filename ? filename + '.doc' : 'document.doc';
    var downloadLink = document.createElement("a");
    document.body.appendChild(downloadLink);
    downloadLink.href = url;
    downloadLink.download = filename;
    downloadLink.click();
    document.body.removeChild(downloadLink);
    DisplayLoader(false, '')
  }
  const createOMRConfig = () => {
    let OMRConfig: any = {}
    let subjectList = ['physics', 'chemistry', 'maths', 'biology']
    subjectList.forEach((subject: string) => {
      let count = challenge.sections.filter((section: any) => {
        return sections[section].tags.indexOf(subject) != -1
      }).length;
      if (count > 0) {
        OMRConfig[subject] = count;
      }
    })
    return OMRConfig;
  }
  const brandingContent = <div className="container d-flex justify-content-center align-items-center" style={{ minHeight: '100px' }}>
    <div className="text-center">
      <img
        src={institution.logoUrl}
        style={{ maxWidth: '100px', position: 'absolute', left: '75px' }}
        className="me-3"
        alt={institution.name}
      />
      <h2>{institution.name}</h2>
      <h5>{title}</h5>
      <h5>{new Date().toLocaleDateString('en-GB')}</h5>
    </div>
  </div>
  return (
    <div className="">
      <>
        <div className='no-print text-center'>
          {/* <div className='mb-4'>
            <input type='checkbox' className='me-1' onChange={(event: ChangeEvent<HTMLInputElement>) => {
              setShowAnswer(event.target.checked);
            }} /> Show Answer
            <input type='checkbox' className='ms-2 me-1' onChange={(event: ChangeEvent<HTMLInputElement>) => {
              setShowExplanation(event.target.checked);
            }} /> Show Explanation
            <input type='checkbox' className='ms-2 me-1' onChange={(event: ChangeEvent<HTMLInputElement>) => {
              setShowTopics(event.target.checked);
            }} /> Show Topics
          </div> */}
          
          <button type='button' className='btn btn-info p-2 me-4' onClick={() => { print() }}>Print {challenge.presentationMode == 'assignment' ? 'Assignment' : 'Test'}</button>
          
        </div>
        <div id="print" style={{ width: '100%' }}>
          <PageContent institutionName={institution.name} questions={sections?.map((section: any, index: number) => {
            return <div className='mb-4' style={{ pageBreakInside: 'avoid' }}>
              {showTopics && <div><b>Topic -</b> {section.mainTopics?.join(',')} <b>Subtopics -</b> {section.fineTags?.subtopics?.join(',')}, <b>Difficulty - </b>{targetTestToDifficultyLevel[section.fineTags?.targetTest]}</div>}
              {PrintSection(section, showAnswer, showExplanation, index + 1)}
            </div>
          })} maxPageHeight={1950} topContent={brandingContent}
            answerContent={<div>{brandingContent}{PrintAnswers(sections)}</div>}
            explanations={sections?.map((section: any, index: number) => {
              return <div className='mb-4' style={{ pageBreakInside: 'avoid' }}>
                {showTopics && <div><b>Topic -</b> {section.mainTopics?.join(',')} <b>Subtopics -</b> {section.fineTags?.subtopics?.join(',')}, <b>Difficulty - </b>{targetTestToDifficultyLevel[section.fineTags?.targetTest]}</div>}
                {PrintExplanation(section, index + 1)}
              </div>
            })} />
          { }
        </div>
      </>
    </div>
  )
}

export { PrintSections }

