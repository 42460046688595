import { Route, Routes, Navigate } from 'react-router-dom'
import { MasterLayout } from '../../_metronic/layout/MasterLayout'
import { DashboardWrapper } from '../pages/dashboard/DashboardWrapper'
import { CreateChallenge } from '../pages/create-challenge/CreateChallenge'
import { ShowChallenge } from '../pages/create-challenge/ShowChallenge'
import { Challenge } from '../pages/challenge/Challenge'
import { StartLesson } from '../pages/challenge/StartLesson'
import { JoinOrganisation } from '../pages/utils/JoinOrganisation'
import { Settings } from '../pages/utils/Settings'
import { AllChallenges } from '../pages/challenge/AllChallenges'
import { CompositeReports } from '../pages/reports/CompositeReports'
import { BatchList } from '../pages/batches/BatchList'
import { TimeTable } from '../pages/attendance/Timetable'
import { Attendance } from '../pages/attendance/Attendance'
import { BatchHome } from '../pages/batches/BatchHome'
import { BatchReports } from '../pages/reports/BatchReports'
import { Exercise } from '../pages/challenge/Exercise'
import { CreateSectionsFromJSON } from '../pages/process/CreateSectionsFromJSON1'
import { Scholarship } from '../pages/others/Scholarship'
import { Review } from '../pages/create-challenge/Review'
import { CreateModule } from '../pages/create-challenge/CreateModule'
import { Reports } from '../pages/reports/Reports'
import { DeleteAccount } from '../pages/utils/DeleteAccount'
import { Messages } from '../pages/messages/Messages'
import { DeliveryStatus } from '../pages/messages/DeliveryStatus'
import { BatchUsers } from '../pages/batches/BatchUsers'
import { AttendanceReport } from '../pages/attendance/AttendanceReport'
import { GenerateChallenge } from '../pages/create-challenge/GenerateChallenge'
import { StudentReports } from '../pages/reports/StudentReports'
import { TagStats } from '../pages/stats/TagStats'
import { WeightagePU } from '../pages/stats/WeightagePU'
import { ChallengeOffline } from '../pages/offline/ChallengeOffline'
import { Print } from '../pages/create-challenge/Print'
import { Fees } from '../pages/batches/Fees'
import MindMap from '../modules/components/display/MindMap'
import AnnotatableImage from '../modules/components/display/ImageAnnotation'
import Sample from '../modules/components/display/Sample'
import { BatchTeachers } from '../pages/batches/BatchTeachers'
import { OfflineReports } from '../pages/reports/OfflineReports'
import { OfflineReportsQuestion } from '../pages/reports/OfflineReportsQuestion'
import { OfflineReportsGroup } from '../pages/reports/OfflineReportsGroup'
import { OfflineReportsDetailed } from '../pages/reports/OfflineReportsDetailed'
import { OfflineReportsSummary } from '../pages/reports/OfflineReportsSummary'
import { NTATest } from '../pages/challenge/NTATest'
import { CreateSectionsFromJSON2 } from '../pages/process/CreateSectionsFromJSON2'
import { Profile } from '../pages/utils/Profile'
import { GenerateChallengev2 } from '../pages/create-challenge/GenerateChallengev2'
import { PrintSections } from '../pages/create-challenge/PrintSections'

const PrivateRoutes = () => {
  return (
    <Routes>
      <Route element={<MasterLayout isRelative={true} />}>
        <Route path='assignment/:id' element={<Exercise />} >
          <Route path=':sectionNo' element={<Exercise />} />
        </Route>
        <Route path='lesson/:id' element={<Exercise />} >
          <Route path=':sectionNo' element={<Exercise />} />
        </Route>
        <Route path='test/:id' element={<Exercise />} >
          <Route path=':sectionNo' element={<Exercise />} />
        </Route>
        <Route path='testseries/:id' element={<NTATest />} >
          <Route path=':sectionNo' element={<NTATest />} />
        </Route>
      </Route>
      <Route element={<MasterLayout isRelative={false} />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path='auth/*' element={<Navigate to='/' />} />
        {/* Pages */}
        <Route index element={<DashboardWrapper />} />
        <Route path='scholar' element={<Scholarship />} />
        <Route path='sample' element={<Sample />} />
        <Route path='create-sections' element={<CreateSectionsFromJSON />} />
        <Route path='create-sections/:id' element={<CreateSectionsFromJSON />} />
        <Route path='create-sections2' element={<CreateSectionsFromJSON2 />} />
        <Route path='create-sections2/:id' element={<CreateSectionsFromJSON2 />} />
        <Route path='batch-home/:batchId' element={<BatchHome />} />
        <Route path='batch-users/:batchId' element={<BatchUsers />} />
        <Route path='batch-teachers/:batchId' element={<BatchTeachers />} />
        <Route path='fees/:batchId' element={<Fees />} />
        <Route path='batches' element={<BatchList />} />
        <Route path='batch-reports/:batchId' element={<BatchReports />} />
        <Route path='attendance-report/:batchId' element={<AttendanceReport />} />
        <Route path='attendance/:sessionId' element={<Attendance />} />
        <Route path='timetable/:batchId' element={<TimeTable />} />
        <Route path='messages/:batchId' element={<Messages />} />
        <Route path='delivery-status/:userId' element={<DeliveryStatus />} />
        <Route path='class-reports' element={<OfflineReports />} />
        <Route path='class-reports-question/:id' element={<OfflineReportsQuestion />} />
        <Route path='class-reports-group/:id' element={<OfflineReportsGroup />} />
        <Route path='class-reports-detailed/:id' element={<OfflineReportsDetailed />} />
        <Route path='class-reports-summary' element={<OfflineReportsSummary />} />
        <Route path='composite-reports/:id' element={<CompositeReports />} />
        <Route path='reports/:id/:userId' element={<Reports />} />
        <Route path='reports/:id' element={<Reports />} />
        <Route path='student-reports/:userId' element={<StudentReports />} />
        <Route path='student-reports' element={<StudentReports />} />
        <Route path='delete-account' element={<DeleteAccount />} />
        <Route path='all-challenges' element={<AllChallenges />}></Route>
        <Route path='settings' element={<Settings />}></Route>
        <Route path='profile' element={<Profile />}></Route>
        <Route path='profile/:id' element={<Profile />}></Route>
        <Route path='join-organisation/:id' element={<JoinOrganisation />}></Route>
        <Route path='show-challenge/:id' element={<ShowChallenge />}></Route>
        <Route path='generate' element={<GenerateChallenge />} />
        <Route path='generate-test' element={<GenerateChallengev2 />} />
        <Route path='lesson/:id' element={<Exercise />} />
        <Route path='print/:id' element={<Print />} />
        <Route path='print-test' element={<PrintSections />} />
        <Route path='start-lesson/:id' element={<StartLesson />} />
        <Route path='review' element={<Review />}></Route>
        <Route path='tag-stats' element={<TagStats />}></Route>
        <Route path='topic-weightage' element={<WeightagePU />}></Route>
        <Route path='create-challenge' >
          <Route path=":id" element={<CreateChallenge />} >
            <Route path=":presentState" element={<CreateChallenge />} />
          </Route>
          <Route path="" element={<CreateChallenge />} />
        </Route>
        <Route path='create-module' >
          <Route path=":id" element={<CreateModule />} >
            <Route path=":presentState" element={<CreateModule />} />
          </Route>
          <Route path="" element={<CreateModule />} />
        </Route>
        <Route path='create-test' >
          <Route path=":id" element={<CreateModule />} >
            <Route path=":presentState" element={<CreateModule />} />
          </Route>
          <Route path="" element={<CreateModule />} />
        </Route>
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

export { PrivateRoutes }
