import React, { useEffect, useRef } from 'react';
import 'katex/dist/katex.min.css';
import katex from 'katex';

interface KaTeXRendererProps {
  children: string;
}

const KaTeXRenderer: React.FC<KaTeXRendererProps> = ({ children }) => {
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (containerRef.current) {
      (window as any).renderMathInElement(containerRef.current, {
        delimiters: [
          { left: '$$', right: '$$', display: true },
          { left: '$', right: '$', display: false },
          { left: '\\(', right: '\\)', display: false },
          { left: '\\[', right: '\\]', display: false },
        ],
        throwOnError: false,
      });
    }
  }, [children]);

  return (
    <div ref={containerRef} dangerouslySetInnerHTML={{ __html: children }}>
      
    </div>
  );
};

export default KaTeXRenderer;



// import React, { useEffect, useRef } from 'react';
// declare global {
//   interface Window {
//     MathJax: {
//       typesetPromise: (elements?: HTMLElement[]) => Promise<void>;
//     };
//   }
// }
// interface KaTeXRendererProps {
//     children: string;
// }

// const KaTeXRenderer: React.FC<KaTeXRendererProps> = ({ children }) => {
//     const mathJaxRef = useRef<HTMLDivElement>(null);

//     useEffect(() => {
//       const renderMath = () => {
//         if (window.MathJax && mathJaxRef.current) {
//           window.MathJax.typesetPromise([mathJaxRef.current]).catch((err: any) =>
//             console.error('MathJax render error:', err)
//           );
//         }
//       };
  
//       renderMath();
//     }, [children]);

//     return <div ref={mathJaxRef} dangerouslySetInnerHTML={{ __html: children }} />;
// };

// export default KaTeXRenderer;

// import React, { useEffect, useRef } from 'react';
// import 'katex/dist/katex.min.css';
// import katex from 'katex';

// interface KaTeXRendererProps {
//   children: string;
// }

// // Utility function to convert LaTeX to SVG, with display mode option
// function latexToSVG(latex: string, isDisplayMode: boolean = false): string {
//   const html = katex.renderToString(latex, { throwOnError: false, displayMode: isDisplayMode, output: "mathml"});
//   const width = isDisplayMode ? 300 : 200;  // Adjust width for display mode
//   const height = isDisplayMode ? 80 : 50;   // Adjust height for display mode
//   return `
    
//           ${html}
//         `
// }

// // Function to replace both inline ($...$) and display ($$...$$) LaTeX with SVGs
// function replaceLatexWithSVG(htmlText: string): string {
//   return htmlText
//     // Replace display-style LaTeX ($$...$$ and \[...\])
//     .replace(/\$\$([\s\S]*?)\$\$|\\\[([\s\S]*?)\\\]/g, (match, latex) => latexToSVG(latex || match, true))
//     // Replace inline-style LaTeX ($...$ and \(...\))
//     .replace(/\$(.*?)\$|\\\((.*?)\\\)/g, (match, latex) => latexToSVG(latex || match, false));
// }

// const KaTeXRenderer: React.FC<KaTeXRendererProps> = ({ children }) => {
//   const containerRef = useRef<HTMLDivElement>(null);

//   useEffect(() => {
//     if (containerRef.current) {
//       // Process the LaTeX content by converting to SVG
//       const svgContent = replaceLatexWithSVG(children);
//       containerRef.current.innerHTML = svgContent;
//     }
//   }, [children]);

//   return <div ref={containerRef}></div>;
// };

// export default KaTeXRenderer;
